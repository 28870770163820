.#{$app-brand-prefix}-megamenu {
	.navbar {
		margin: 0;
		border: 0;
		position: inherit;
	}
	.navbar-collapse {
		padding: 0;
	}
	.navbar-nav {
		> li {
			height: 62px;
			&.parent {
				a {
					padding-right: 28px;
				}
			}
			&.aligned-left {
				position: inherit;
				.dropdown-menu {
					left: -1px;
					right: auto;
				}
				.dropdown-submenu {
					.dropdown-menu {
						left: 100%;
					}
				}
			}
			> .dropdown-menu {
				margin-left: 15px;
				width: calc(100% - 30px);
				top: 100%;
				@include opacity(0);
				@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
				display: block;
				position: absolute;
				visibility: hidden;
			}
			&:hover,
			&:focus {
				> .dropdown-menu {
					@include opacity(1);
					visibility: visible;
					display: block;
					top: 100%;
				}
			}
			> a {
				padding: 20px 18px 20px 18px;
				font-size: $megamenu-link-font-size;
				font-weight: 700;
				font-family: $f;
				text-transform: uppercase;
				color: $lg;
				background-color: transparent !important;
				
				line-height: 22px;
				&:hover, &:focus, &:active {
					color: $white;
					background: url(#{$image-theme-path-base}menu_bg1.png) center center no-repeat;
					.caret:before {
						color: $white !important;
					}
				}
				.caret {
					border: 0;
					position: relative;
					&:before {
						@include transition(all 200ms ease-out);
						content: '\f110';
						font-family: $fa;
						position: absolute;
						right: -10px;
						top: -12px;
						font-size: $font-size-base - 1;
						font-weight: 100;
						color: $lg;
					}
				}
			}
			&.full-width {
				position: static;
			}
		}
	}
	/* level 2 */
	.dropdown-menu {
		top: 100%;
		left: -9999px;
		height: auto;
		color: $text-color;
		min-width: $megamenu-sub-min-width;
		padding: 50px 0;
		background: url("#{$image-theme-path}menu_bg2.jpg") bottom left no-repeat #fff;
		border-bottom: 3px solid $trd;
		@include rounded-corners(0);
		@include transition(opacity 0.3s linear 0s);
		z-index: 9999;
		ul, ol {
			padding: 0;
		}
		li {
			width: 33.33333%;
			float: left;
			padding: 0;
			line-height: 20px;
			list-style: none;
			position: relative;
			border:0;
			border-right: 1px solid #dedede;
			background: none !important;
			a {
				padding: 12px 50px;
				display: inline-block;
				width: 100%;
				color: #333333;
				font-family: $fbs;
				font-size: $font-size-base + 2;
				font-weight: 400;
				&:hover {
					color: $trd;
					background: none!important;
				}
				.image {width: 60px; text-align: center; margin: 0 15px 0 0; display: inline-block;}
				img {height: 50px; width: auto; display: inline-block;}
			}
			&.haschildren {
				&:after {
					position: absolute;
					content: '\f112';
					font-family: $fa;
					font-size: 15px;
					@include size(10px, 17px);
					right: 8px;
					top: 12px;
				}
				&:hover:after {
					background-position: center -17px;
				}
			}
			> ul.children {
				top: 5px;
				left: 285px;
				right: -250px;
				position: absolute;
				background: $white;
				display: none;
				z-index: 99;
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
				> li {
					a {
						padding: 8px 12px;
						color: $bk;
						border-bottom: 1px #ebebeb solid;
					}
					&:hover {
						background: $tc;
						a {
							color: $white;
						}
					}
					&:last-child {
						border: none;
					}
				}
			}
			&:hover {
				> ul.children {
					left: 100%;
					display: block;
					top: 0;
				}
			}
		}
	}
	.dropdown {
		&:hover {
			> .dropdown-menu {
				display: block;
				left: 0;
			}
			> a {
				.fa {
					color: $bk;
				}
			}
		}
	}
	.mega-group {
		& > .dropdown-toggle {
			border: 0;
			display: block;
			letter-spacing: 3px;
			text-transform: uppercase;
			font-family: $megamenu-heading-title;
			color: $megamenu-heading-color;
			.fa {
				color: $white;
			}
		}
	}
	.widget-heading {
		font-size: $font-size-base + 2;
		margin: 0 0 20px;
	}
	.widget-images {
		margin-bottom: 20px;
		padding-top: 5px;
	}
	.megamenu .cols1 {
		min-width: 200px;
	}
	.megamenu .cols2 {
		min-width: 500px;
	}
	.megamenu .cols3 {
		min-width: 740px;
	}
	.sidebar .product-block {
		padding: 5px 0;
		border-bottom: none;
		.image {
			padding: 0;
		}
	}
}

.ocwall {
	background: transparentize($tc, .15);
	position: absolute;
	width: 100%;
	height: 100%;
	right: -100%;
	top: 0;
	opacity: 0;
	visibility: hidden;
	@include transition(opacity 0.2s ease-in-out);
	.menutoggle {
		position: fixed;
		@include size(50px, 50px);
		background: url('/image/i/closew.png') center center no-repeat;
		background-size: cover;
		top: calc(50% - 25px);
		left: calc(85vw - 25px);
	}
}

.row-offcanvas.active {
	.ocwall {
		opacity: 1;
		visibility: visible;
		z-index: 9999;
	}
}

#column-left {
	.tree-menu {
		background: url(#{$image-theme-path-base}menu_bg3.png) right bottom no-repeat $wh;
		-webkit-box-shadow: 0 0 13px 0 #d1d1d1;
    	-moz-box-shadow: 0 0 13px 0 #d1d1d1;
    	box-shadow: 0 0 13px 0 #d1d1d1;
    	padding: 25px 0 50px 0;
    	&:before {
	content:'';
	width:234px;
	height:20px;
	display:block;
	position:absolute;
	left: 50%;
	margin-left:-117px;
	top:-7px;
	z-index:1;
	background: url(#{$image-theme-path-base}product2.png) no-repeat;	
		}
		
		li {
			padding: 4px 10px; 
			border:0;
			position: relative;
			&:hover {
				background: none;
			}
			&:nth-child(2n) {
				background: #f8f8f8;
			}
			a {
				color: #000;
				font-family: $fbs;
				font-size: $font-size-base + 1;
				font-weight: bold;
				text-decoration: none;
				display: flex;
				align-items: center;
				img {
					max-width: 40px; 
					height: auto;
					margin: 0 10px 0 0;
				}
				&:hover {
					color: $trd;
				}
			}
			.accordion-heading {
				position: absolute;
				right: 10px;
				top: 12px;
				.fa {
					width: 10px;
					height: 9px;
					&:before {
						display: none;
					}
					&.fa-angle-down {
						background: url(#{$image-theme-path-base}menu_arrow.png) right center no-repeat;		
					}
					&.fa-plus {
						background: url(#{$image-theme-path-base}menu_arrow.png) right center no-repeat;		
					}
					&.fa-minus {
						background: url(#{$image-theme-path-base}menu_arrow.png) left center no-repeat;		
					}
				}
			}
			ul {
				background: inherit;
				padding: 0;
				li {
					&:nth-child(2n) {
						background: inherit;
					}
					a {
						padding-left: 15px;
						background: url(#{$image-theme-path-base}menu_arrow2.png) left 6px no-repeat;
						font-weight: normal;
					}
				}
			}
		}
		
	}
}