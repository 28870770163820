body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

/* search */
#searchtop {
	margin-top: 6px;
	input.form-control {
		background: none;
		border-radius: 0;
		border: 0;
		border-bottom: 1px solid $wh;
		color: $gray;
		@include placeholder($bc);
		padding-left: 0;
		height: 40px;
		display: inline-block;
	}
	.input-group-btn {
		background: none;
		text-align: center;
		width: 36px;
		border-bottom: 1px solid $wh;
		@include transition(all 0.2s ease-in-out);
	}
	.btn-search {
		display: inline-block;
		background: none;
		font-size: 18px !important;
		cursor: pointer;
		color: $red;
		padding: 0 0 0 20px;
		height: 39px;
	}
}

.form-control::-moz-placeholder {
	color: $bc;
}

// module cart
#cart {
	height: 100%;
	display: inline-block;
	width: auto;
	&.open .dropdown-toggle {
		box-shadow: none;
	}
	a {
		background: url(#{$image-theme-path-base}cart.png) left top no-repeat;
		padding: 30px 0 30px 65px;
		display: inline-block;
		position: relative;
		text-decoration: none;
		min-width: 116px;
	}
	.icon-cart {
		background: transparent;
		display: block;
		position: relative;
		text-align: center;
		padding: 0 15px;
		height: 31px;
		i {
			font-size: 36px;
			line-height: 28px;
		}
	}
	.wrap-cart {
		color: $wh;
		font-size: 14px;
		font-weight: bold;
		span {
			position: relative;
			padding-bottom: 16px;
		}
		.badge {
			position: absolute !important;
			top: 8px !important;
			right: 13px;
			padding: 3px;
			min-width: 17px;
			line-height: 11px;
			z-index: 99;
			background: $tc;
		}
	}
	.price-cart {
		color: $tc;
	}
	.cart-inner {
		text-align: right;
	}
}

.product-compare-header {
	position: relative;
	display: inline-block;
	width: auto;
	height: 100%;
	text-align: center;
	cursor: pointer;
	margin: 32px 0 0 0;
	a {
		color: $bk;
		div {
			display: inline-block;
			padding-left: 5px;
		}
	}
	p {
		margin: 0;
		&#compare-total {
			line-height: 19px;
			font-size: 13px;
		}
	}
	i {
		font-size: 32px;
		line-height: 31px;
		display: block;
	}
	.badge {
		color: $trd;
		position: absolute !important;
		top: -7px !important;
		right: -6px;
		padding: 3px;
		min-width: 17px;
		line-height: 11px;
		z-index: 99;
		background: $wh;
	}
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

#slider_home {
	padding: 10px 0 50px 0;
	.owl-prev {
		top: auto;
		bottom: 10px;
		left: 0;
		right: auto;
		color: #d21a20;
		width: 45px;
		height: 50px;
		background: url(#{$image-theme-path-base}slider_text.png) left top no-repeat #000;
		text-align: center;
		line-height: 50px;
	}
	.owl-next {
		top: auto;
		bottom: 10px;
		left: 48px;
		right: auto;
		color: #d21a20;
		width: 45px;
		height: 50px;
		background: #000;
		text-align: center;
		line-height: 50px;
	}
	.slider_home_item {
		width: 100%;
		height: 600px;
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: contain;
		.top_title {
			font-family: $fbs;
			font-weight: 500;
			font-size: 30px;
			color: $trd;
			margin-top: 100px;
		}
		.title {
			font-family: $fbs;
			font-weight: 800;
			font-size: 58px;
			color: $wh;
			line-height: 62px;
		}
		.text {
			font-size: 18px;
			color: $wh;
			padding-left: 30px;
			padding-right: 25px;
			margin: 15px 0;
			background: url(#{$image-theme-path-base}slider_text.png) left 10px no-repeat;
		}
		a {
			&:hover, &:active, &:focus {
				text-decoration: none;
			}
		}
		.button {
			margin-top: 40px;
			a {
				width: 220px;
				height: 56px;
				background: url(#{$image-theme-path-base}slider_button.png) no-repeat;
				line-height: 56px;
				color: $wh;
				font-family: $fbs;
				font-weight: 800;
				font-size: 14px;
				text-transform: uppercase;
				text-decoration: none;
				display: block;
				text-align: center;
			}
		}
	}
}

#featured_categories {
	padding: 45px 0 0 0;
	.title {
		background: rgb(234, 84, 0);
		background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: 800;
		font-family: $fbs;
		font-size: 30px;
		text-transform: uppercase;
	}
	.sub_title {
		color: #31363c;
		font-size: 16px;
	}
	.featured_cat {
		height: 300px;
		width: 100%;
		margin: 25px 0;
		background-size: contain;
		background-position: bottom center;
		.featured_cat_block {
			width: 100%;
			height: 100%;
			background-size: contain;
			background-repeat: no-repeat;
		}
		.featured_cat_info {
			width: 70%;
			padding: 60px 50px 0 50px;
			.title {

				color: $wh;
				font-size: 32px;
				font-weight: 800;
				font-family: $fbs;
				-webkit-text-fill-color: unset;
				text-decoration: none;
				line-height: 36px;
				text-transform: uppercase;
				position: relative;
				padding-bottom: 15px;
				margin-bottom: 15px;
				display: block;
				&:after {
					content: '';
					width: 50px;
					position: absolute;
					bottom: 0;
					left: 0;
					border-bottom: 1px solid #fff;
				}

			}
			.desc {
				font-size: 16px;
				line-height: normal;
				color: #fff;
			}
		}
	}
	.cat_1 {
		background: url(#{$image-theme-path-base}cat1.png) center bottom no-repeat;
		.featured_cat_block {
			background-position: left top;
		}
		.featured_cat_info {
			float: right;
		}
	}
	.cat_2 {
		background: url(#{$image-theme-path-base}cat3.png) center bottom no-repeat;
		.featured_cat_block {
			background-position: right top;
		}
		.featured_cat_info {
			float: left;
			.title {

				color: #333333;
				&:after {
					border-bottom: 1px solid #333;
				}

			}
			.desc {
				color: #333333
			}
		}
	}
	.cat_3 {
		background: url(#{$image-theme-path-base}cat2.png) center bottom no-repeat;
		.featured_cat_block {
			background-position: right top;
		}
		.featured_cat_info {
			float: left;
		}

	}
	.cat_4 {
		background: url(#{$image-theme-path-base}cat4.png) center bottom no-repeat;
		.featured_cat_block {
			background-position: left top;
		}
		.featured_cat_info {
			float: right;
		}
	}
	.owl-prev {
		background: none;
		right: auto;
		top: 47%;
		left: -45px;
		color: #e8420b;
		font-size: 60px;
		border: 0;
	}
	.owl-next {
		background: none;
		left: auto;
		top: 47%;
		right: -20px;
		color: #e8420b;
		font-size: 60px;
		border: 0;
	}
	.all_catalog {
		text-align: center;
		padding: 30px 0 60px 0;
		position: relative;
		z-index: 1;
		a {
			color: $trd;
			font-size: 15px;
			border-bottom: 2px solid #333333;
			text-decoration: none;
			font-weight: bold;
		}
	}
}

#featured {
	padding: 120px 0;
	h4 {
		margin: 0
	}
	.title {
		color: $wh;
		font-weight: 800;
		font-family: $fbs;
		font-size: 20px;
		text-transform: uppercase;
		line-height: normal;
		margin: 13px 0 0 0;
	}
	.sub_title {
		color: $wh;
		font-weight: 800;
		font-family: $fbs;
		font-size: 44px;
		line-height: normal;
	}
	.owl-prev {
		top: 50%;
		bottom: auto;
		left: -25px;
		margin-top: -25px;
		right: auto;
		color: #d21a20;
		width: 45px;
		height: 50px;
		background: url(#{$image-theme-path-base}slider_text.png) left top no-repeat #000;
		text-align: center;
		line-height: 50px;
	}
	.owl-next {
		top: 50%;
		bottom: auto;
		left: auto;
		right: -25px;
		margin-top: -25px;
		color: #d21a20;
		width: 45px;
		height: 50px;
		background: #000;
		text-align: center;
		line-height: 50px;
	}
}

#bestseller {
	padding: 25px 0 50px 0;
	margin: 0 0 10px 0;
	.title {
		background: rgb(234, 84, 0);
		background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: 800;
		font-family: $fbs;
		font-size: 30px;
		text-transform: uppercase;
		text-align: center;
		margin: 25px 0 0 0;
	}
	.sub_title {
		color: #31363c;
		font-size: 16px;
		text-align: center;
		margin: 0 0 50px 0;
	}
	.owl-prev {
		background: none;
		right: auto;
		top: 50%;
		margin-top: -30px;
		left: -45px;
		color: #e8420b;
		font-size: 60px;
		border: 0;
	}
	.owl-next {
		background: none;
		left: auto;
		top: 50%;
		margin-top: -30px;
		right: -20px;
		color: #e8420b;
		font-size: 60px;
		border: 0;
	}
}

#preim {
	min-height: 718px;
	padding: 45px 0 25px 0;
	#preim-block {
		position: relative;
		.preim-title {
			position: absolute;
			left: 0;
			top: 0;
			.title {
				color: $wh;
				font-weight: 800;
				font-family: $fbs;
				font-size: 24px;
				line-height: 26px;
				text-transform: uppercase;
			}
			.sub_title {
				color: $wh;
				font-size: 14px;
			}
		}
		.image {
			text-align: center;
		}
		.text {
			text-align: center;
			color: $wh;
			font-weight: bold;
			font-size: 13px;
			line-height: 15px;
			margin: 15px 0 35px 0;
		}
	}
	#about-company {
		position: relative;
		padding: 80px 0 0 0;
		.about-company-link {
			position: absolute;
			bottom: 11px;
			left: 0;
			a {
				font-size: 14px;
				text-transform: uppercase;
				text-decoration: underline;
				color: $trd;
				font-weight: bold;
			}
		}
		.title {
			background: rgb(234, 84, 0);
			background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-weight: 800;
			font-family: $fbs;
			font-size: 30px;
			text-transform: uppercase;
			text-align: right;
			margin: 0;
			line-height: 32px;
		}
		.sub_title {
			color: #31363c;
			font-size: 16px;
			text-align: right;
			margin: 0 0 25px 0;
			padding-right: 25px;
		}
		.image {
			position: relative;
			z-index: 0;
		}
		.link {
			position: absolute;
			z-index: 1;
			bottom: 50px;
			left: 0;
			width: 100%;
			text-align: center;
			a {
				color: $wh;
				font-family: $fbs;
				font-weight: 800;
				font-size: 14px;
				text-transform: uppercase;
				text-decoration: none;
				text-align: center;
				display: inline-block;
				border: 2px solid $wh;
				border-radius: 5px;
				padding: 10px 15px;
				&:hover {
					background: $trd;
					border-color: $trd;
				}
			}
		}
	}
}

#latest {
	padding: 25px 0 50px 0;
	margin: 0;
	.title {
		background: rgb(234, 84, 0);
		background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: 800;
		font-family: $fbs;
		font-size: 30px;
		text-transform: uppercase;
		text-align: center;
		margin: 25px 0 0 0;
	}
	.sub_title {
		color: #31363c;
		font-size: 16px;
		text-align: center;
		margin: 0 0 25px 0;
	}
	.owl-prev {
		background: none;
		right: auto;
		top: 50%;
		margin-top: -30px;
		left: -45px;
		color: #e8420b;
		font-size: 60px;
		border: 0;
	}
	.owl-next {
		background: none;
		left: auto;
		top: 50%;
		margin-top: -30px;
		right: -20px;
		color: #e8420b;
		font-size: 60px;
		border: 0;
	}
}

#partneram, #certificate-banner {
	padding: 70px 0 70px 0;
	.title {
		color: $wh;
		font-weight: 800;
		font-family: $fbs;
		font-size: 30px;
		line-height: 32px;
		text-transform: uppercase;
	}
	.text {
		color: $wh;
		font-size: 14px;
		line-height: 20px;
	}
	.link {
		text-align: center;
		button, a {
			color: $wh;
			font-family: $fbs;
			font-weight: 800;
			font-size: 14px;
			text-transform: uppercase;
			text-decoration: none;
			background: none;
			text-align: center;
			display: inline-block;
			border: 2px solid $wh;
			border-radius: 5px;
			width: 100%;
			padding: 15px 20px;
			&:hover {
				background: $trd;
			}
		}
	}
}

#viewed {
	background: url(#{$image-theme-path-base}fon_latest.jpg) center bottom no-repeat;
	padding: 25px 0 50px 0;
	margin: 0;
	.title {
		background: rgb(234, 84, 0);
		background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: 800;
		font-family: $fbs;
		font-size: 30px;
		text-transform: uppercase;
		text-align: center;
		margin: 25px 0 0 0;
	}
	.sub_title {
		color: #31363c;
		font-size: 16px;
		text-align: center;
		margin: 0 0 50px 0;
	}
	.owl-prev {
		background: none;
		right: auto;
		top: 50%;
		margin-top: -30px;
		left: -45px;
		color: #e8420b;
		font-size: 60px;
		border: 0;
	}
	.owl-next {
		background: none;
		left: auto;
		top: 50%;
		margin-top: -30px;
		right: -20px;
		color: #e8420b;
		font-size: 60px;
		border: 0;
	}
}

#related {
	background: url(#{$image-theme-path-base}fon_latest.jpg) center bottom no-repeat;
	padding: 25px 0 50px 0;
	margin: 0;
	.title {
		background: rgb(234, 84, 0);
		background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: 800;
		font-family: $fbs;
		font-size: 30px;
		text-transform: uppercase;
		text-align: center;
		margin: 25px 0 0 0;
	}
	.sub_title {
		color: #31363c;
		font-size: 16px;
		text-align: center;
		margin: 0 0 25px 0;
	}
	.owl-prev {
		background: none;
		right: auto;
		top: 50%;
		margin-top: -30px;
		left: -45px;
		color: #e8420b;
		font-size: 60px;
		border: 0;
	}
	.owl-next {
		background: none;
		left: auto;
		top: 50%;
		margin-top: -30px;
		right: -20px;
		color: #e8420b;
		font-size: 60px;
		border: 0;
	}
}

.homebuilder {
	.pav-container {
		position: relative;
		&.featured_cat {
			.pav-inner {
				&:before {
					content: 'ПОПУЛЯРНЫЕ';
					left: 12%;
					top: -125px;
					font-size: 116pt;
					color: $ddg;
					opacity: .04;
					font-family: $fbs;
					font-weight: 800;
					position: absolute;
				}
				background: url(#{$image-theme-path-base}img_popular.png) left 135% no-repeat;
			}
		}
		&.featured {
			&:before {
				content: '';
				background: url(#{$image-theme-path-base}recomend_bck.png) left top no-repeat;
				position: absolute;
				left: 0;
				right: 0;
				top: -300px;
				bottom: 0;
			}
			.pav-inner {
				&:before {
					position: absolute;
					content: 'РЕКОМЕНДУЕМЫЕ';
					right: 12%;
					left: 35%;
					font-size: 106pt;
					color: $tc;
					opacity: .1;
					font-family: $fbs;
					font-weight: 800;
				}
			}
		}
		&.hits {
			background: url(#{$image-theme-path-base}fon_hits.png) center bottom no-repeat;
		}
		&.preim {
			background: url(#{$image-theme-path-base}fon_preim.jpg) center top no-repeat;
		}
		&.latest {
			background: url(#{$image-theme-path-base}fon_latest.jpg) center bottom no-repeat;
		}
		&.partner {
			background: url(#{$image-theme-path-base}fon_partneram.jpg) center bottom no-repeat;
		}
		&.certificate-banner {
			background: url(https://cdn.ada-shop.ru/image/i/banner_certificate_ada.png) center bottom no-repeat;
		}
	}
}

#widget_news {
	.title {
		background: rgb(234, 84, 0);
		background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: 800;
		font-family: $fbs;
		font-size: 30px;
		line-height: 32px;
		text-transform: uppercase;
		margin: 35px 0 0 0;
	}
	.sub_title {
		color: #31363c;
		font-size: 16px;
		margin: 0 0 25px 0;
	}
	.link {
		padding: 45px 0 0 0;
		a {
			font-weight: bold;
			font-size: 14px;
			text-transform: uppercase;
			text-decoration: underline;
			color: $trd;
		}
	}
	.widget_news_item_img {
		text-align: center;
		padding: 0 0 5px 0;
		background: url(#{$image-theme-path-base}news_image.png) center bottom no-repeat;
		margin: 0 0 15px 0;
	}
	.widget_news_item_desc {
		width: 100%;
		overflow: hidden;
		.widget_news_item_date {
			background: url(#{$image-theme-path-base}news_date.png) left center no-repeat;
			width: 30%;
			float: left;
			height: 50px;
			line-height: 50px;
			padding-left: 20px;
			font-weight: bold;
			color: #000;
		}
		.widget_news_item_title {
			width: 70%;
			padding: 5px 0 0 0;
			float: left;
			a {
				font-weight: bold;
				text-decoration: none;
				font-size: 14px;
				line-height: normal;
				color: #000;
			}
		}
	}
	.widget_news_item_description {
		margin: 15px 0 25px 0;
		font-size: 14px;
		line-height: normal;
		color: #000;
	}
}

#tm-newsletter {
	padding: 0 0 25px 0;
	margin: 25px 0 0 0;
	.title {
		background: rgb(234, 84, 0);
		background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: 800;
		font-family: $fbs;
		font-size: 30px;
		line-height: 32px;
		text-transform: uppercase;
		margin: 0;
	}
	.sub_title {
		color: #31363c;
		font-size: 16px;
		line-height: 18px;
		margin: 0;
	}
	.tm-login-form {
		width: 100%;
		background: #fff;
		border: 1px solid #e4e4e4;
		border-radius: 5px;
		padding: 3px 3px 3px 20px;
		font-size: 14px;
		overflow: hidden;
		input {
			background: none;
			border: 0;
			width: 50%;
			color: #000;
			line-height: 36px;
			height: 36px;
			outline: none !important;
		}
		button {
			float: right;
			background: url(#{$image-theme-path-base}to_cart.png) repeat-x;
			border: 0;
			color: $wh;
			font-size: 14px;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 36px;
			height: 36px;
			padding: 0 15px;
			border-radius: 5px;
			text-align: center;
			&:hover {
				background: url(#{$image-theme-path-base}to_cart2.png) repeat-x;
			}
		}
	}
}

#checkout-page {
	h1.title {
		background: rgb(234, 84, 0);
		background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: 800;
		font-family: $fbs;
		font-size: 30px;
		line-height: 32px;
		text-transform: uppercase;
		text-align: center;
		margin: 0;
		padding: 0;
	}
	.sub_title {
		color: #31363c;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 30px;
	}
	#buy_form_container {
		.shopping-cart-table,
		.shopping-cart-product-deleted {
			-webkit-box-shadow: 0 0 13px 0 #d1d1d1;
			-moz-box-shadow: 0 0 13px 0 #d1d1d1;
			box-shadow: 0 0 13px 0 #d1d1d1;
			position: relative;
			margin: 0 0 40px 0;
			&:before {
				content: '';
				width: 100%;
				height: 4px;
				position: absolute;
				top: -4px;
				left: 0;
				display: block;
				background: url(#{$image-theme-path-base}review.png) top center repeat-x #fff;
			}
			tr {
				&:nth-child(2n-1) {
					td {
						background: #f9f9f9;
						&:nth-child(2), &:nth-child(4) {
							background: #fff;
						}
					}
				}
				&:nth-child(2n) {
					td {
						background: #f5f5f5;
						&:nth-child(2), &:nth-child(4) {
							background: #f9f9f9;
						}
					}
				}
				td {
					border: 0;
					vertical-align: middle;
					padding: 15px;
					&.column-image {
						padding: 25px;
						img {
							-webkit-box-shadow: 0 0 13px 0 #d1d1d1;
							-moz-box-shadow: 0 0 13px 0 #d1d1d1;
							box-shadow: 0 0 13px 0 #d1d1d1;
							width: 150px;
							min-width: 150px;
						}
					}
					&.column-name {
						a {
							display: block;
							font-weight: bold;
							font-size: 16px;
							color: #000;
							text-decoration: none;
							line-height: 20px;
						}
						.price {
							margin: 10px 0 0 0;
							span {
								color: $trd;
								font-size: 18px;
								font-weight: bold;
								font-family: $f;
								.old {
									color: #4c4c4c;
									font-size: 14px;
									text-decoration: line-through;
									padding-right: 30px;
								}
							}
						}
					}
					&.column-quantity {
						.qty {
							width: 116px;
							button {
								float: left;
								border: 1px solid #cccccc;
								width: 28px;
								height: 40px;
								line-height: 40px;
								color: $trd;
								background: #fff;
								&.remove {
									border-radius: 5px 0 0 5px;
								}
								&.add {
									border-radius: 0 5px 5px 0;
								}
								&:hover {
									color: #fff;
									background: $trd;
								}
							}
							input {
								float: left;
								text-align: center;
								border: 1px solid #cccccc;
								border-left: 0;
								border-right: 0;
								width: 60px;
								height: 40px;
								line-height: 40px;
								color: #333333;
							}
						}
					}
					&.column-total {
						text-align: center;
						span {
							display: block;
							color: $trd;
							font-size: 18px;
							font-weight: bold;
							font-family: $f;
							white-space: nowrap;
						}
					}
					&.column-delete {
						button {
							border: 0;
							background: none;
							padding: 0;
							img {
								width: 21px;
								height: 22px;
								display: block;
								max-width: none;
							}
						}
					}
				}
			}
		}
	}
	#coupon_form {
		.title {
			background: rgb(234, 84, 0);
			background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-weight: 800;
			font-family: $fbs;
			font-size: 28px;
			line-height: 30px;
			text-transform: uppercase;
			text-align: left;
			margin: 25px 0 0 0;
			padding: 0;
		}
		.sub_title {
			color: #31363c;
			font-size: 15px;
			font-weight: normal;
			text-align: left;
			margin-bottom: 20px;
		}
		.coupon_button {
			padding: 2px;
			background: #fff;
			border: 1px solid #ccc;
			display: inline-block;
			border-radius: 5px;
			input {
				border: 0;
				background: none;
				font-style: italic;
			}
			button {
				background: url(#{$image-theme-path-base}to_cart.png) repeat-x;
				border: 0;
				color: $wh;
				font-size: 14px;
				font-weight: bold;
				text-transform: uppercase;
				line-height: 36px;
				height: 36px;
				padding: 0 15px;
				border-radius: 5px;
				text-align: center;
				&:hover {
					background: url(#{$image-theme-path-base}to_cart2.png) repeat-x;
				}
			}
		}
	}
	#total_price {
		padding: 25px 25px 0 25px;
		background: url(#{$image-theme-path-base}total.jpg) center center no-repeat #fff;
		background-size: cover;
		-webkit-box-shadow: 0 0 13px 0 #d1d1d1;
		-moz-box-shadow: 0 0 13px 0 #d1d1d1;
		box-shadow: 0 0 13px 0 #d1d1d1;
		overflow: hidden;
		.title {
			background: rgb(234, 84, 0);
			background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-weight: 800;
			font-family: $fbs;
			font-size: 28px;
			line-height: 30px;
			text-transform: uppercase;
			text-align: left;
			margin: 0 0 15px 0;
			padding: 0;
		}
		table {
			tr {
				&:last-child {
					td {
						&:last-child {
							color: $trd;
						}
					}
				}
			}
			td {
				border: 0;
				padding: 2px 0;
				&:first-child {
					text-align: left !important;
					strong {
						font-weight: normal !important
					}
				}
				&:last-child {
					font-weight: bold !important
				}
			}
		}
	}

	#contact-data {
		background: #fff;
		margin: 40px 0 0 0;
		padding: 25px;
		-webkit-box-shadow: 0 0 13px 0 #d1d1d1;
		-moz-box-shadow: 0 0 13px 0 #d1d1d1;
		box-shadow: 0 0 13px 0 #d1d1d1;
		overflow: hidden;
		position: relative;
		&:before {
			content: '';
			width: 100%;
			height: 20px;
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			background: url(#{$image-theme-path-base}checkout1.png) top center no-repeat;
		}
		.title {
			color: #000000;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
			margin: 0 0 15px 0;
			padding: 0;
		}
		.form-group {
			input, textarea {
				-webkit-box-shadow: inset 2px 2px 5px 0px rgba(0, 0, 0, 0.07);
				-moz-box-shadow: inset 2px 2px 5px 0px rgba(0, 0, 0, 0.07);
				box-shadow: inset 2px 2px 5px 0px rgba(0, 0, 0, 0.07);
			}
		}
	}

	#shiping-data {
		background: #fff;
		margin: 40px 0 0 0;
		padding: 25px;
		-webkit-box-shadow: 0 0 13px 0 #d1d1d1;
		-moz-box-shadow: 0 0 13px 0 #d1d1d1;
		box-shadow: 0 0 13px 0 #d1d1d1;
		overflow: hidden;
		position: relative;
		&:before {
			content: '';
			width: 100%;
			height: 20px;
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			background: url(#{$image-theme-path-base}checkout1.png) top center no-repeat;
		}
		h3 {
			color: #000000;
			font-weight: bold;
			font-family: $f;
			font-size: 18px;
			text-align: center;
			margin: 5px 0 15px 0;
			padding: 0;
		}
	}
	#pay-data {
		background: #fff;
		margin: 40px 0 0 0;
		padding: 25px;
		-webkit-box-shadow: 0 0 13px 0 #d1d1d1;
		-moz-box-shadow: 0 0 13px 0 #d1d1d1;
		box-shadow: 0 0 13px 0 #d1d1d1;
		overflow: hidden;
		position: relative;
		&:before {
			content: '';
			width: 100%;
			height: 20px;
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			background: url(#{$image-theme-path-base}checkout1.png) top center no-repeat;
		}
		h3 {
			color: #000000;
			font-weight: bold;
			font-family: $f;
			font-size: 18px;
			text-align: center;
			margin: 5px 0 15px 0;
			padding: 0;
		}
		#payment_methods {
			.radio_custom {
				width: 50%;
				float: left;
				#payment_methodcod {
					&:after {
						content: 'gfg';
						position: relative;
						width: 50px;
						height: 50px;
						opacity: 1;
					}
				}
			}
		}
	}

	.radio, .checkbox {
		text-transform: none;
	}
}

.modal-title {
	color: $trd;
	font-weight: 800;
	font-family: $fbs;
	font-size: 28px;
	line-height: 30px;
	text-transform: uppercase;
	text-align: center;
	margin: 15px 0 15px 0;
	padding: 0;
}