.breadcrumb {
	> li {
		display: inline-block;
		&:first-child {
			a span {
				font-size: 14px;
				line-height: 1;
			}
		}
		+ li:before {
			content: " — "; // Unicode space added since inline-block means non-collapsing white-space
			padding: 0 5px;
			color: $trd;
		}
	}
	> .active {
		font-weight: 600;
	}
}