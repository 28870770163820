// DEFAULT OPTIONS

// Prefix for Hover class names
$nameSpace: 'ef';

// Durations
$fastDuration: .1s;
$mediumDuration: .3s;
$slowDuration: .5s;

// Colors
$primaryColor: #e1e1e1;
$secondaryColor: #666;
$highlightColor: #ccc;
$activeColor: #2098D1;
$shadowColor: rgba(0, 0, 0, .6);

// Speech bubbles options
$tipWidth: 10px;  //the width of the speech bubble tip
$tipHeight: 10px; //the height of the speech bubble tip
$tipColor: $primaryColor;

// Arrows options
$spaceBetweenTextAndArrows: 2.2em;

// Curl dimensions
$curlWidth: 25px;
$curlHeight: 25px;

// Curl colors
$revealAreaColor: $body-bg;    // reveal area behind the curl
$curlLineColor: #aaa;       // crease line
$curlTransitionColor: #ccc; // color transitioning from crease line to final color
$curlLastColor: white;      // final color of the back of the curl

// Browser Prefixes - Which CSS prefixes should be used?
$webkit: true;
$moz: false;
$ms: false;
$o: false;