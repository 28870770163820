.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: 600;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: 1px solid transparent;
	white-space: nowrap;
	@include button-size(0, 15px, 14px, $input-height-base, 0);
	@include user-select(none);
	text-transform: uppercase;
	font-family: $f;
	height: $input-height-base;
	&,
	&:active,
	&.active {
		&:focus,
		&.focus {
			@include tab-focus;
		}
	}

	&:hover,
	&:focus,
	&.focus {
		color: $link-hover-color;
		text-decoration: none;
	}

	&:active,
	&.active {
		outline: 0;
		background-image: none;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: $cursor-disabled;
		pointer-events: none;
		@include opacity(.65);
		@include box-shadow(none);
	}
}

// Alternate buttons
// --------------------------------------------------

.btn-default {
	@include button-variant($wh, $btn-default-bg, $btn-default-border);
}

.btn-primary {
	@include button-variant($btn-primary-color, $tc, $btn-primary-border);
}

// Success appears as green
.btn-success {
	@include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

// Info appears as blue-green
.btn-info {
	@include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}

// Warning appears as orange
.btn-warning {
	@include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

// Danger and error appear as red
.btn-danger {
	@include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
	// color: $brand-success;

	&,
	&:active,
	&.active,
	&[disabled],
	fieldset[disabled] & {
		background-color: transparent;
		@include box-shadow(none);
	}
	&,
	&:hover,
	&:focus,
	&:active {
		//border-color: transparent;
	}
	&:hover,
	&:focus {
		color: $link-color;
		text-decoration: none;
		background-color: transparent;
	}
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus {
			color: $btn-link-disabled-color;
			text-decoration: none;
		}
	}
}

// Button Sizes
// --------------------------------------------------
.btn-lg {
	// line-height: ensure even-numbered height of button next to large input
	height: 46px;
	@include button-size(18px, 33px, $font-size-base - 1, 10px, $border-radius-large);
}

.btn-md {
	// line-height: ensure even-numbered height of button next to large input
	height: 42px;
	@include button-size(12px, 25px, $font-size-large - 5, $line-height-large, $border-radius-large);
}

.btn-sm {
	height: 40px;
	@include button-size(0px, 25px, $font-size-base - 2, 40px, 3px);
}

.btn-xs {
	height: 32px;
	@include button-size(0, 20px, $font-size-base - 1, 31px, 3px);
	font-weight: 500;
}

// Block button
// --------------------------------------------------

.btn-block {
	display: block;
	width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
	margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}
