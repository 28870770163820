
.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.product_right_info {
	padding: 15px 15px 15px 30px;
	border: 1px solid #f0f0f0;
	overflow: hidden;
	margin-bottom: 15px;
}

.price {
	font-weight: 400;
	font-family: $hf;
	.price-new {
		color: $bk;
		font-size: 22px;
	}
	.price-old {
		text-decoration: line-through;
		color: $mg;
		font-size: 18px;
	}
	&.detail {
		.price-new {
			font-size: 30px;
			display: block;
			line-height: 32px;
			font-family: $f;
			font-weight: bold;
		}
		.price-old {
			font-size: 16px;
			display: block;
			font-family: $f;
			font-weight: bold;
		}
		ul {
			margin: 0 0 15px 0;
			li {
				display: block;
				text-align: right;
			}
		}
	}
}

.cheap_invoice {
	margin: 10px 0 0 0;
	a {
		font-size: 14px;
		line-height: 16px;
		margin-bottom: 5px;
		text-decoration: underline;
		display: block;
		color: #333333;
		&:hover {
			color: $bk;
			text-decoration: underline;
		}
		&.invoice {
			text-decoration: underline;
		}
	}
}

.product-block {
	position: relative;

	-webkit-box-shadow: 0 0 13px 0 rgba(209, 209, 209, 1);
	-moz-box-shadow: 0 0 13px 0 rgba(209, 209, 209, 1);
	box-shadow: 0 0 13px 0 rgba(209, 209, 209, 1);
	margin: 15px 0;
	z-index: 0;
	.description {
		display: none;
	}
	.product-block-item {
		background-color: $white;
		overflow: hidden;
		width: 100%;
	}
	&:before {
		content: '';
		width: 234px;
		height: 54px;
		display: block;
		position: absolute;
		left: 50%;
		margin-left: -117px;
		top: -7px;
		z-index: 1;
		background: url(#{$image-theme-path-base}product2.png) no-repeat;
	}
	&:after {
		content: '';
		width: 234px;
		height: 54px;
		display: block;
		position: absolute;
		left: 50%;
		margin-left: -117px;
		bottom: -8px;
		z-index: 1;
		background: url(#{$image-theme-path-base}product1.png) no-repeat;
	}
	.block-img {
		width: 50%;
		float: left;
		.img {
			padding-top: 80px;
			display: block;
		}
	}
	.product-meta {
		width: 50%;
		line-height: 25px;
		padding: 0;
		float: right;
		position: relative;
		z-index: 2;
	}
	.bottom {
		display: block;
		margin: 15px 0 5px 0;
		overflow: hidden;
		width: 100%;
		padding: 0 0 0 10px;
		.to_cart {
			display: block;
			float: left;
			background: url(#{$image-theme-path-base}to_cart.png) repeat-x;
			border: 0;
			color: $wh;
			font-size: 14px;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 36px;
			height: 36px;
			padding: 0;
			border-radius: 5px;
			text-align: center;
			width: 70%;
			&:hover {
				background: url(#{$image-theme-path-base}to_cart2.png) repeat-x;
			}
		}
		.to_compare {
			display: block;
			float: right;
			background: url(#{$image-theme-path-base}to_compare.png) center center no-repeat;
			width: 30%;
			height: 36px;
			border: 0;
			&:hover {
				background: url(#{$image-theme-path-base}to_compare2.png) center center no-repeat;
			}
		}
	}
	.btn-oneclick {
		display: block;
		padding: 10px 0 15px 10px;
		background: none;
		border: 0;
		line-height: 14px;
		span {
			color: #999999;
			text-decoration: underline;
			font-size: 13px;
			font-weight: bold;
		}
		&:hover {
			span {
				color: #000000;
			}
		}
	}
	.price {
		text-align: left;
		background: #f6f6f6;
		padding: 5px 0 5px 15px;
		.price-old {
			display: block;
			color: #666666;
			font-size: 14px;
			font-weight: bold;
			font-family: $f;
		}
		.price-new {
			display: block;
			color: $trd;
			font-size: 22px;
			font-weight: bold;
			font-family: $f;
		}
		.price-raz {
			display: block;
			color: $trd;
			font-size: 10px;
			font-weight: bold;
			font-family: $f;
		}
	}
	.name {
		margin: 25px 0 10px 0;
		padding: 0 5px 0 15px;
		font-size: $font-size-base;
		line-height: $font-size-base + 5;
		font-family: $font-family-base;
		text-transform: inherit;
		overflow: hidden;
		font-weight: 600;
		a {
			color: #000 !important;
			padding: 5px 0;
			&:hover {
				color: $bk;
			}
			span {
				text-align: center;
				display: block;
				&.title1 {
					font-weight: 400;
				}
				&.title2 {
					font-family: $hf;
					font-weight: 500;
					font-size: 18px;
					line-height: 26px;
				}
			}
		}
		.rating {
			font-size: 14px;
			margin-top: 7px;
			text-align: left;
			.fa-star {
				width: 17px;
				height: 16px;
				background: url(#{$image-theme-path-base}star-1.png) no-repeat;
				&:before {
					display: none;
				}
			}
			.fa-star-half-o {
				width: 17px;
				height: 16px;
				background: url(#{$image-theme-path-base}star-half.png) no-repeat;
				&:before {
					display: none;
				}
			}
			.fa-star-o {
				width: 17px;
				height: 16px;
				background: url(#{$image-theme-path-base}star-0.png) no-repeat;
				&:before {
					display: none;
				}
			}
		}
	}
	.image {
		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
		padding: 10px 10px 0 10px;
	}
	.top {
		position: relative;
		padding: 0;
	}
}

.cart {
	position: relative;
	@include transition(all 0.4s ease 0s);
	padding-bottom: 15px;
	.icon-cart {
		display: block;
		@include size(16px, 17px);
	}
}

.quickview {
	position: absolute;
	left: 15px;
	top: 15px;
	z-index: 99;
	a {
		display: block;
		width: 21px;
		height: 21px;
		background: url(#{$image-theme-path-base}quick-view.png) center top no-repeat;
	}
}

.product-label {
	font-size: $font-size-base - 2;
	font-weight: 800;
	font-family: $fbs;
	text-transform: uppercase;
	z-index: 99;
	position: absolute;
	text-align: left;
	line-height: 20px;
	top: 45px;
	left: 0;
	.sale {
		display: inline-block;
		background: url(#{$image-theme-path-base}akciya.png) center top no-repeat;
		color: $wh;
		padding: 0 5px;
		margin: 2px 0;
	}
	.new {
		display: inline-block;
		background: url(#{$image-theme-path-base}new.png) center top no-repeat;
		color: $wh;
		padding: 0 5px;
		margin: 2px 0;
	}
}

.products-block {
	.product-label-pickup {
		padding: 0 5px;
		margin-top: 2px;
		display: block;
		background: url(#{$image-theme-path-base}akciya.png) center top;
		white-space: nowrap;
		div {
			display: inline;
			color: $wh;
			&:first-child {
				margin-right: 5px;
			}
		}
	}
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}

.product-info {
	.tab-content {
		background: url(#{$image-theme-path-base}razdel1.png) right top no-repeat;
		border: none;
		padding: 0;
		margin-top: 25px;
		margin-bottom: 30px;
		.desc-hr {
			background: url(#{$image-theme-path-base}razdel1.png) left center no-repeat;
			width: 100%;
			height: 6px;
			margin: 15px 0;
		}
		.desc-header-wrapper {
			margin-top: 15px;
			margin-bottom: 15px;
			.desc-header {
				background: rgb(234, 84, 0);
				background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				font-weight: 800;
				font-family: $fbs;
				font-size: 26px;
				text-transform: uppercase;
			}
		}
		#tab-description {
			padding: 0 15px;
		}
		#tab-specification {
			table {
				border: 0;
			}
			tr {
				&:nth-child(2n) {
					td {
						background: #f6f6f6;

					}
				}
			}
			td {
				padding: 10px 15px !important;
				color: #333333 !important;
				border: 0;
				font-size: 14px;
				&:first-child {
					font-weight: bold;
				}
				strong {
					font-size: 18px;
					background: rgb(234, 84, 0);
					background: linear-gradient(90deg, rgba(234, 84, 0, 1) 0%, rgba(227, 29, 35, 1) 100%);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}
		}
		#tab-downloads {
			a {
				min-height: 74px;
				padding: 0 0 0 70px;
				background: url(#{$image-theme-path-base}pdf.png) left center no-repeat;
				color: #333333;
				display: flex;
				align-items: center;
			}
		}
		#review-list {
			.desc-header-wrapper {
				overflow: hidden;
				margin: 45px 0 5px 0;
				.desc-header {
					font-size: 30px;
					float: left;
				}
				.review-button {
					padding: 5px 0 0 0;
					float: right;
					.btn {
						background: url(#{$image-theme-path-base}to_cart.png) repeat-x;
						border: 0;
						color: $wh;
						font-size: 14px;
						font-weight: bold;
						text-transform: uppercase;
						line-height: 36px;
						height: 36px;
						border-radius: 5px;
						text-align: center;
						&:hover {
							background: url(#{$image-theme-path-base}to_cart2.png) repeat-x;
						}

					}
				}
			}

		}
		.owl-related {
			.owl-next, .owl-prev {
				top: -57px;
			}
			.owl-next {
				right: 18px;
			}
			.owl-prev {
				right: 48px;
			}
			.owl-stage {
				padding: 0;
			}
			.product-block {
				width: 100%;
				margin: 0;
				.image {
					border: none;
				}
			}
		}
		.widget-products {
			margin: 0 -2px;
		}
		.scroll-button {
			margin-bottom: 5px;
		}
		.product-grid.no-scroll {
			.product-block {
				margin: 4px 2px;
				width: calc(100% / 4 - 7px);
				.image {
					border: 0;
				}
			}
		}
	}
}

.storage_widget {
	line-height: 16px;
	color: #333333;
	& > span:nth-child(2) {
		color: #333333 !important;
		@include transition(all .2s ease-in-out);
	}
	table tr {
		td:last-child span {
			background: none !important;
			border-radius: 5px !important;
			color: #333333 !important;
			font-size: 14px !important;
			border: 2px solid #e31d23;
			text-transform: uppercase;
			&:hover {
				background: #e31d23 !important;
				color: #fff !important;
			}
		}
	}
}

.widget-text {
	display: block;
	font-size: 12px;
	line-height: 15px;
	position: absolute;
	width: 800px;
}

#rev1 {
	display: none;
}