.owl-carousel {
	display: block !important;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}

.filter-right {
	& > div {
		display: flex;
		align-items: center;
	}
	.sort {
		margin-left: 0;
	}
	label {
		line-height: 14px;
	}
}

.refine-search {
	margin-top: 15px;
	ul, ol {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		li {
			padding: 10px 0;
			margin: 0 10px;
			line-height: normal;
			position: relative;
			width: calc(33.333333% - 20px);
			a {
				display: flex;
				flex-wrap: wrap;
				color: $bk;
				background: no-repeat transparent center center;
				background-size: 70%;
				padding-top: 150px;
				box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
				border-bottom: 2px solid #e31d23;
				position: relative;
				&:hover {
					text-decoration: none;
					span {
						color: $wh;
						background: url(#{$image-theme-path-base}category2.png) no-repeat;
						background-size: cover;
					}
				}
				&:before {
					content: '';
					width: 56px;
					height: 54px;
					position: absolute;
					left: -3px;
					top: -4px;
					background: url(#{$image-theme-path-base}category.jpg) no-repeat;
				}
				span {
					max-width: 90%;
					-webkit-clip-path: polygon(90% 0, 100% 30%, 100% 100%, 0 100%, 0 0);
					clip-path: polygon(90% 0, 100% 30%, 100% 100%, 0 100%, 0 0);
					padding: 10px 45px;
					font-family: $fbs;
					font-weight: 600;
					color: $wh;
					background: rgba(0, 0, 0, .6);
					font-size: 20px;
					text-transform: uppercase;
					line-height: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					@include transition (all 200ms ease-in-out);
					figure {
						text-align: center;
					}
				}
			}
			@media (max-width: 767px) {
				width: calc((100% / 3) - 20px);
			}
			@media (max-width: 500px) {
				width: calc((100% / 2) - 20px);
			}
			@media (max-width: 400px) {
				font-size: 12px;
			}
		}
	}
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.col-md-12 {
	.refine-search ul li, .refine-search ol li {
		width: calc(20% - 20px);
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}

}

.product-info {
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
		}
	}
}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

/* 
 *  LISTING PRODUCTS PAGE 
 */
.product-filter {
	color: $product-filter-color;
	text-transform: uppercase;
	font-size: 14px;
	margin: 10px 0;
	span {
		margin-right: 5px;
		text-transform: none;
	}
	.filter-right > div {
		padding-top: 3px;
		padding-left: 5px;
	}

	.display {
		button {
			margin: 0;
			padding: 0;
			background: url(#{$image-theme-path-base}display3.jpg) no-repeat;
			&:first-child {
				border-radius: 5px 0 0 5px;
			}
			&:last-child {
				border-radius: 0 5px 5px 0;
			}
			.fa {
				width: 44px;
				height: 44px;

				&:before {
					content: '';
				}
				&.fa-th-list {
					&:before {
						margin: 4px 0 0 5px;
						display: block;
						width: 26px;
						height: 26px;
						background: url(#{$image-theme-path-base}display1.png) center right no-repeat;
					}
				}
				&.fa-th-large {
					&:before {
						margin: 5px 0 0 5px;
						display: block;
						width: 26px;
						height: 26px;
						background: url(#{$image-theme-path-base}display2.png) center left no-repeat;
					}
				}

			}
			&.active {
				background: #fc0a0a;
				.fa {
					&.fa-th-list {
						&:before {
							background: url(#{$image-theme-path-base}display1.png) center left no-repeat;
						}
					}
					&.fa-th-large {
						&:before {
							background: url(#{$image-theme-path-base}display2.png) center right no-repeat;
						}
					}
				}
			}
		}
	}
	.limit {
		margin-left: 15px;
	}
	.selectric-form-control {
		-webkit-box-shadow: 0 0 13px 0 rgba(209, 209, 209, 1);
		-moz-box-shadow: 0 0 13px 0 rgba(209, 209, 209, 1);
		box-shadow: 0 0 13px 0 rgba(209, 209, 209, 1);

	}
	.form-control {
		display: inline-block;
		padding: 0 20px;
		margin: 0 5px;
		@include size(auto, 36px);
		text-transform: capitalize;
	}
	.btn-switch {
		display: inline-block;
		margin-right: 5px;
		color: #969696;
		background: transparent;
		padding: 0;
		line-height: 35px;
		font-weight: 500;
		font-size: 34px;
		@include transition (all 200ms ease 0s);
		i {
			padding: 0 5px;
		}
		&:hover, &.active {
			color: $theme-color;
			@include box-shadow (none);
		}
		&#grid-switch {
			font-size: 32px;
		}
	}
}

.product-list {
	.description {
		display: none;
	}
	.product-meta {
		padding-left: 0;
	}
	.product-block {
		display: flex;
		border: 1px solid #eee;
		margin: 20px 0;
		border-radius: 0;
		&:after {
			display: none;
		}
		&:before {
			height: 20px;
		}
		.block-img {

			.img {
				padding-top: 0;
				display: block;
				text-align: center;
				img {
					display: inline-block;
				}
			}
		}
		.name {
			font-weight: 600;
			font-size: 18px;
			height: auto !important;
			a {
				span {
					display: inline-block;
				}
			}
		}
		.price {
			margin-bottom: 6px;
			text-align: left;
			.price-new {
				font-size: 26px;
			}
		}
		.block-img {
			width: 50%;
		}
		.bottom {
			display: flex;
			padding: 0 20px;
			.cart {
				button {
					padding: 0 30px;
				}
				display: inline-block;
			}
			.quickview {
				a {
					left: -1px;
					position: relative;
				}
			}
		}
		.btn-oneclick {
			width: 100%;
			text-align: center;
		}
	}
}

#products {
	.products-block {
		margin: 0 -17px;
	}
}

.product-grid, .products-owl-carousel {
	&.product-related {
		.products-block {
			margin: 5px;
		}
		.product-block {
			width: calc(100% / 4 - 13px);
		}
	}
	.product-block {
		display: inline-block;
		margin: 15px;
		padding-bottom: 0;
		width: calc(100% / 3 - 33px);
		border: 1px solid $lg;
		@include rounded-corners(0);
		@include transition(border 0.2s ease-in-out);
		&:after {
			display: none;
		}
		&:before {
			height: 20px;
		}
		.block-img {
			width: 100%;
			float: none;
			.img {
				padding-top: 0;
			}
		}
		.product-meta {
			width: 100%;
			float: none;
			text-align: center;
			.name {
				margin: 0;
			}
			.rating {
				text-align: center;
			}
			.price {
				text-align: center;
				.price-old {
					font-size: 18px;
					font-weight: bold;
				}
				.price-new {
					font-size: 34px;
					font-weight: bold;
				}
			}
			.btn-oneclick {
				width: 100%;
				text-align: center;
			}
		}
		.cart {
			padding: 0;
			flex: 1 1 auto;
			.btn {
				width: 100%;
			}
		}
		.compare {
			flex: 0 0 36px;
			i {
				font-size: 22px;
				vertical-align: -2px;
			}
		}
		.quickview {

		}
		.compare {
			a, button {
				width: 100%;
			}
		}
		.bottom {
			@include transition (all 400ms ease 0s);
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 999;
			background-color: $white;
			text-align: center;
			display: block;
			padding: 0 20px;
			position: relative;
			.to_cart {
				width: 140px;
				display: inline-block;
				float: none;
			}
			.to_compare {
				width: 40px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
		&:hover {
			border-color: $bc;
		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.product-block {
		width: auto;
	}
}

.page-product-search, .product-special {
	.product-grid {
		.product-block {
			margin: 15px 5px;
			width: calc(100% / 4 - 13px);
		}
	}
}

/* 
* PRODUCT DEALS
*/
.productdeals {
	.widget-inner {
		.carousel {
			position: static;
		}
	}
}

/* 
* PRODUCT INFORMATION PAGE
*/
.product-info {
	.box-product-infomation {
		margin-top: 20px;
	}
	.title-product {
		font-size: 26px;
		margin-top: 0;
		font-weight: normal;
		line-height: normal;
		font-family: $fbs;
		color: #4c4c4c;
		padding: 0;
	}
	.image-additional {
		overflow: hidden;
		.owl-carousel {
			margin-bottom: 0 !important;
		}
	}
	.image-additional .item {
		border: 0;
		text-align: center;
		margin: 0 2px;
		background-color: $white;
		position: relative;
		img {
			display: inline-block;
		}
		a.active {
			&:before {
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $theme-color;
				content: "";
				left: 0;
				z-index: 1;
				top: -1px;
			}
			&:after {
				position: absolute;
				left: 50%;
				margin-left: -8px;
				border: 6px solid transparent;
				border-bottom: 6px solid $theme-color;
				content: "";
				z-index: 1;
				top: -12px;
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.vertical {
		&.image-additional {
			.carousel-control {
				display: none;
			}
		}
		&:hover {
			.carousel-control {
				display: block;
			}
		}

		.center {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		.top {
			top: 0;
			bottom: auto;
		}

		.bottom {
			top: auto;
			bottom: 0;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;

		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image-container {
		.image {
			position: relative;
			text-align: center;
			overflow: hidden;
			border: 0;
			.product-label {
				width: auto;
				height: 20px;
				background: url(#{$image-theme-path-base}main_image1.png) no-repeat;
				color: #fff;
				line-height: 20px;
				text-align: center;
				font-size: 14px;
				top: 0;
				padding: 0 10px;
				+ .product-label {
					top: 30px;
				}
				&.sale {
					background: url(#{$image-theme-path-base}akciya.png) repeat-x;
				}
				&.new {
					background: url(#{$image-theme-path-base}new.png) repeat-x;
				}
				&.pickup {
					height: 50px;
					width: 89px;
					font-weight: 400;
					padding: 10px 10px 10px 0;
					font-family: $f;
					div {
						&:first-child {
							font-size: 15pt;
						}
						&:last-child {
							font-size: 8pt;
							text-transform: lowercase;
							line-height: 1;
						}
					}
				}
			}
		}
	}
	.product-view {
		.prod_rating {
			font-size: 14px;
			line-height: 16px;
			margin-top: 7px;
			text-align: left;
			padding: 10px 15px 25px 15px;
			span {
				display: inline-block;
			}
			.stars {
				float: left;
				margin-right: 5px;
			}
			.fa-star {
				width: 17px;
				height: 16px;
				line-height: 16px;
				background: url(#{$image-theme-path-base}star-1.png) center center no-repeat;
				&:before {
					display: none;
				}
			}
			.fa-star-half-o {
				width: 17px;
				height: 16px;
				line-height: 16px;
				background: url(#{$image-theme-path-base}star-half.png) center center no-repeat;
				&:before {
					display: none;
				}
			}
			.fa-star-o {
				width: 17px;
				height: 16px;
				line-height: 16px;
				background: url(#{$image-theme-path-base}star-0.png) center center no-repeat;
				&:before {
					display: none;
				}
			}
			.custom-color {
				color: #898993;
				font-weight: bold;
				&.otziv_button {
					display: inline-block;
					padding: 0 50px;
					text-decoration: underline;
					color: #333;
				}

			}
			.stock {
				background: url(#{$image-theme-path-base}stock.png) left center no-repeat;
				color: #339933;
				text-transform: uppercase;
				padding: 0 0 0 20px;
				font-weight: bold;
			}

		}
		.harakteristiki {
			li {
				padding: 5px 0;
				border-bottom: 1px solid #dedede;
				color: #7f7f7f;
				span {
					color: #333333;
				}
				&:first-child {
					padding-top: 0;
				}
			}
		}
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity {
		padding-bottom: 15px;
	}
	.quantity-adder {
		display: block;
		position: relative;
		background-color: $white;
		@include border-radius(#dedede, 5px);
		& + .btn-sm {
			padding: 0 18px;
		}
		.add-action {
			@include size(30px, 35px);
			color: $black;
			display: block;
			position: relative;
			cursor: pointer;
			font-size: 8px;
			line-height: 35px;
			text-align: center;
			&.add-down {
				border-right: 1px solid #dedede;
				border-radius: 5px 0 0 5px;
			}
			&.add-up {
				border-left: 1px solid #dedede;
				border-radius: 0 5px 5px 0;
			}
			&:hover, &:focus {
				color: #fff;
				background: $trd;

			}
		}
		.quantity-number {
			input {
				@include size(90px, 35px);
				text-align: center;
				@include inline-block;
				@include rounded-corners(0);
				box-shadow: none;
				padding: 0;
				border: none;
				color: $black;
				font-size: 14px;
			}
		}
		.minimum {
			line-height: 35px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	.product-meta {
		a {
			font-weight: 500;
			a.btn-sm {
				padding: 0 19px;
			}
			&.btn-primary {
				margin-left: 16px;
				font-size: 20px;
				line-height: 33px;
				padding: 0 10px;
			}
		}
		.cart {
			padding-bottom: 0;
			font-size: 0;
		}
		.btn-compare {
			color: $bk;
			font-size: 24px;
			padding: 2px 18px;
			height: 41px;
			&:hover {
				color: $trd;
			}
		}
		.button-compare {
			display: inline-block;
			color: #4c4c4c;
			font-size: 10px;
			line-height: 35px;
			padding: 0 30px 0 18px;
			font-weight: bold;
			background: url(#{$image-theme-path-base}to_compare2.png) right center no-repeat;
			height: 35px;
			&:hover {
				color: $trd;
				text-decoration: none;
				background: url(#{$image-theme-path-base}to_compare.png) right center no-repeat;
			}
		}
	}
	.advantages_block {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 55px;
		.advantage_item {
			width: 50%;
			min-width: 262px;
			display: flex;
			align-items: center;
			div {
				@include size(34px, 30px);
				margin: 0;
			}
			span {
				width: calc(100% - 50px);
				margin: 10px 0 10px 10px;
				font-size: 15px;
			}
		}
		&.nostock {
			margin: 0;
			position: absolute;
			width: calc(1000px / 3);
		}
	}
}

.product-markdown-view {
	.cheaper-products, .cheaper-items, .cheaper-item, .cheaper-item-info, .product_right_info, .product-meta, .cart  {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.price {
		display: flex;
		gap: 16px;
		align-items: flex-end;

		.price-new, .price-old {
			line-height: 1;
		}
	}
	.quantity-adder, .quantity {
		display: flex;
		width: max-content;
	}
	.product_right_info {
		padding: 20px;
		margin-bottom: 20px;
		border: 1px solid $dg;
	}
	.quantity {
		padding-bottom: 0;
		gap: 16px;
	}
	.btn-credit, .cheap_invoice {
		margin: 0;

		a {
			margin: 0;
		}
	}
	.credit_row {
		width: auto;
		justify-content: flex-start;
		gap: 16px;
	}
	.product-meta {
		.button-compare {
			width: max-content;
			padding-left: 0;
			font-size: 14px;
			font-weight: 500;
		}
	}
	.cheaper-products, .cheaper-item {
		&__title {
			font-size: 16px;
			font-weight: 600;
		}
		&__reason {
			color: $mg;
		}
		&__status {
			font-weight: 600;
			color: $mg;
		}
		&:hover {
			text-decoration: none;
		}
	}
	.cheaper-item {
		padding: 20px;
		border: 1px solid $gray;

		&__title {
			display: flex;
			justify-content: space-between;
		}
	}
	.stock-inner {
		padding: 0!important;
	}
	#button-cart {
		width: 100%;
		max-width: 265px;
	}
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}

.h1_container {
	padding: 0;
	text-align: center;
	h1 {
		margin: 0 auto;
		padding: 0 0 10px 0;
		color: $trd;
		font-weight: 800;
		font-family: $fbs;
		font-size: 30px;
		text-transform: uppercase;
	}
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}

.paging {
	.pull-center {
		text-align: center;
	}
}
