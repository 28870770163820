@media (min-width: 1200px) {
	.harakteristiki {
		min-height: 120px;
	}
	.widget-text {
		margin-top: 36px;
	}
}

@media (min-width: 992px) {
	i.click-allcat {
		display: none;
	}
	#cart {
		margin-left: 15px;
	}

}

@media (min-width: 768px) {
	.quick-view .image-container {
		width: 50% !important;
	}
	.quick-view .price.detail .price-new {
		font-size: 24px;
	}

}

@media (max-width: $screen-md-max) {
	.mfilter-content {
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 75px;
			min-width: 75px;
			padding: 9px 7px;
		}
	}
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu .navbar-nav > li > a {
		padding: 14px 10px;
		font-size: 14px;
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}
	.home3 {
		.featured-category {
			ul li {
				.caption {
					padding: 20px 5px;
				}
			}
			.image {
				margin: 5px 0 5px 10px;
			}
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
	.mfilter-free-container {
		top: 174px;
	}
	.header-bottom {
		.nav-search {
			margin-bottom: 7px;
		}
	}
	.refine-search ul li a span, .refine-search ol li a span {
		line-height: 15px;
		font-size: 12px;
		overflow: hidden;
	}
	.header-menu {
		width: 100%;
	}
	.logo-store a img {
		margin: 0 !important;
	}
	.widget-text {
		width: calc(1000px / 3 * 2);
	}
	.advantages_block.nostock {
		width: 262px;
	}
	#slider_home .slider_home_item .title {
		font-size: 48px;
		line-height: 52px;
	}
}

@media (max-width: $screen-md-max) and (min-width: $screen-md) {
	.logo-store {
		img {
			margin-top: 4px;
		}
	}
	.home3 {
		.widget-blogs {
			.carousel-controls-v1 {
				top: 45px;
				.carousel-control.left {
					left: -65px;
				}
				.carousel-control {
					border: none;
				}
			}
		}
	}
	.quickview, .zoom {
		display: none !important;
	}
}

/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.quickview, .zoom {
		display: none !important;
	}
	.widget-images {
		margin-bottom: 30px;
	}
	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
	}
	#searchtop {
		margin-top: 5px;
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 3 - 33px);
		}
	}
	.header-bottom {
		& > .container {
			height: 100px;
		}
		.logo-container {
			text-align: center;
			flex: 0 0 200px;
			order: 1;
			img {
				max-width: 100%;
			}
		}
		#topcontacts {
			order: 2;
		}
		.headercart {
			position: absolute;
			top: 15px;
			right: 15px;
		}
	}
	.header-menu > .container {
		padding-left: 0;
	}
	#powered {
		.copyright {
			order: 2;
		}
		.payment {
			order: 1;
			text-align: center;
			.payment-img {
				float: none !important;
				max-width: 80%;
				display: inline-block;
			}
		}
	}
	body .mfilter-free-container {
		top: 244px !important;
		padding: 0 !important;
		margin-right: -222px;
		margin-left: auto;
		right: 0;
		left: auto;
		border-color: $bc;
		&.mfilter-direction-rtl {
			margin-right: -222px;
		}
		#mfilter-free-container-0 {
			padding: 5px 10px !important;
			overflow: auto !important;
		}
		.mfilter-box {
			margin-bottom: 5px;
		}
		.mfilter-free-button {
			background: $theme-color;
			margin: 0 220px 0 -45px;
			border: 1px solid $bc;
			padding: 9px 5px;
			top: -1px;
			width: 33px;
			height: 23px;
			&:before {
				content: '\f1f3';
				font-family: $fa;
				color: $white;
				font-size: 28px;
				line-height: 23px;
				padding: 3px;
				left: -1px;
				position: relative;
			}
		}
		#mfilter-opts-price-min, #mfilter-opts-price-max {
			width: 65px;
			min-width: 65px;
			padding: 9px 7px;
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li:hover {
		background-color: $white;
		a {
			color: $bk;
		}
	}
	.breadcrumbs {
		padding: 15px 0;
	}
	.h1_container {
		&:before {
			margin: 33px 10px 10px 0;
		}
		h1 {
			padding: 18px 15px 21px 0;
			font-size: 26px;
		}
	}
	.cat_all {
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 1px 15px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $bk;
			}
			&.haschildren {

				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 40px);
					display: inline-block;
				}
				&.opened ul {
					display: block;
				}
			}
		}
	}
	.category_widget {
		.type1 {
			height: 300px;
			h2 {
				a {
					font-size: 15px;
					padding: 27px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(25% - 20px);
	}
	.main-menu {
		.canvas-menu {
			color: $wh;
			padding: 0 15px;
		}
	}
	.tree-menu ul ul li {
		padding: 2px 0;
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 3 - 7px);
	}
	#slider_home .slider_home_item {
		background-position: 300px bottom;
	}
	.logo-store a span span {
		display: none !important;
	}
	.header-menu {
		height: 116px;
	}
	#header-layout {
		position: relative;
	}
	.header-bottom > .container {
		display: block;
	}
	.header-bottom .logo-container .logo-store {
		position: absolute;
		width: 150px;
		left: 50%;
		margin-left: -75px;
		top: 15px;
		text-align: center;
	}
	.header-bottom .logo-container .logo-store a {
		text-align: center !important;
	}
	#searchtop {
		width: 300px;
		position: absolute;
		left: 24px;
		margin-left: 0;
		top: 110px;
	}
	.product-compare-header {
		margin-right: 25px;
	}
	#cart {
		a {
			padding: 30px 0 40px 0;
			min-width: 56px;
			background: url(#{$image-theme-path-base}cart2.png) left 70% no-repeat;
		}
		.wrap-cart {
			color: $wh;
			font-size: 14px;
			font-weight: bold;
			span {
				position: relative;
				padding-bottom: 16px;
			}
			.badge {
				color: $trd;
				position: absolute !important;
				top: 24px !important;
				right: 3px;
				padding: 3px;
				min-width: 17px;
				line-height: 11px;
				z-index: 99;
				background: $wh;
			}
		}
	}

	.header-bottom .topcontacts .contact1 .phone2 {
		display: none;
	}
	.header-bottom #topcontacts {
		display: block;
		width: 340px;
		position: absolute;
		right: 0;
		top: 95px;
		padding: 15px 0 0 70px;
		.worktime {
			position: absolute;
			right: 5px;
			top: -12px;
		}
	}
	#featured_categories .owl-prev {
		left: -25px;
	}
	#featured_categories .owl-next {
		right: 25px;
	}
	#featured .owl-next {
	}
	#bestseller .owl-prev, #latest .owl-prev {
		left: -25px;
	}
	#bestseller .owl-next, #latest .owl-next {
		right: 25px;
	}
	#related .owl-prev {
		left: -25px;
	}
	#related .owl-next {
		right: 25px;
	}
	#preim #preim-block .preim-title .title {
		font-size: 18px;
		line-height: normal;
	}
	#partneram, #certificate-banner {
		padding: 35px 0 35px 0;
	}
	#partneram, #certificate-banner {
		.link {
			margin: 25px 0 0 0;
		}
	}
	#tm-newsletter {
		margin: 15px 0 0 0;
		padding: 0 0 15px 0;
	}
	#tm-newsletter .sub_title {
		margin: 0 0 10px 0;
	}
	.homebuilder, #footer {
		background-size: cover;
	}
	#powered .ft-addr {
		padding-top: 13px;
	}
	.product-info .product-view .prod_rating .custom-color.otziv_button {
		padding: 0 25px;
	}
	.product_right_info {
		padding: 15px 5px;
	}
	#button-cart {
		padding: 0 15px;
	}
	.title-product.product-title {
		margin: 0 0 15px 0;
		text-align: center;
		padding: 0 15px;
	}
	.stock-top {
		background: url(#{$image-theme-path-base}stock.png) left center no-repeat;
		color: #339933;
		text-transform: uppercase;
		padding: 0 0 0 20px;
		font-weight: bold;
	}
	.pav-megamenu {
		z-index: 15;
	}

	#mob-button {
		position: absolute;
		top: 28px;
		left: 25px;
		display: block !important;
		background: url(#{$image-theme-path-base}close.png) center center no-repeat;
		width: 27px;
		z-index: 4000;
	}
	#mob-button.collapsed {
		background: url(#{$image-theme-path-base}menu_mob.png) center center no-repeat;
	}
	#mob-button .fa-bars:before {
		display: none;
	}
	.main-menu .canvas-menu {
		display: none;
	}
	#bs-megamenu {
		display: none !important;
		width: 320px;
		top: 100px;
		left: 0;
		position: absolute;
		z-index: 1000;
		background: #2b201e;
		&.in {
			display: block !important;
			overflow: hidden;
		}
		.navbar-nav {
			float: none;
			display: block;
			width: 100%;
			margin: 0;
			z-index: 4000;
		}
		li {
			float: none;
			display: block;
		}
	}
	.product-compare-header, #cart .wrap-cart {
		.badge {
			z-index: 0;
		}
	}
	.pav-megamenu .navbar-nav > li.parent a {
		z-index: 4000;
	}
	.pav-megamenu .navbar-nav > li {
		height: auto;
		overflow: hidden;
	}
	.pav-megamenu .navbar-nav > li > a {
		background: url(#{$image-theme-path-base}menu_mob2.png) left center no-repeat;
		border-bottom: 1px solid #fff;
		padding-left: 25px;
		position: relative;
	}
	#header_top {
		position: relative;
	}
	#page > .container {
		position: relative;
		z-index: 0;
	}
	#header_top .main-menu {
		position: inherit;
		z-index: 3;
	}
	#header_top .nav-search {
		z-index: 100;
	}
	.inmenu #header_top .nav-search {
		display: none;
	}
	.header-bottom > .container {
		position: static;
	}
	.pav-megamenu .navbar-nav > li > a .caret {
		position: absolute;
		right: 30px;
		top: 25px;
	}
	.pav-megamenu .navbar-nav > li > a:hover, .pav-megamenu .navbar-nav > li > a:focus, .pav-megamenu .navbar-nav > li > a:active {
		background: url(#{$image-theme-path-base}menu_mob2.png) left center no-repeat;
	}
	.pav-megamenu .navbar-nav > li > .dropdown-menu {
		opacity: 1;
		display: block;
		position: relative;
		width: 100%;
		visibility: visible;
		margin: 0;
		top: auto;
		padding: 0;
		background: #fff;
	}
	.pav-megamenu .navbar-nav > li.open > .dropdown-menu {
		display: block;
	}
	.pav-megamenu .dropdown-menu li {
		width: 100%;
		display: block;
		border: 0;
	}
	.pav-megamenu .dropdown-menu li a {
		padding: 5px 10px;
	}
	.pav-megamenu .dropdown-menu li:nth-child(2n-1) a {
		background: #f8f8f8;
	}
	.pav-megamenu .dropdown-menu li a img {
		height: 40px;
	}
	#pavo-slideshow {
		z-index: 0;
		position: relative;
	}
	#featured_categories {
		background-position: -700px bottom;
	}
	#featured_categories .featured_cat {
		background-size: cover;
	}
	#featured_categories .owl-next {
		right: 10px;
	}
	.featured_categories .swiper-slide {
		padding: 0 30px;
	}
	.product-block .block-img .img {
		padding-top: 20%;
	}
	#latest {
		background-position: -850px bottom
	}
	#featured_categories .cat_1 {
		background-position: center top
	}
	#featured_categories .cat_3 {
		background-position: bottom center;
	}
	#featured_categories .cat_4 {
		background-position: center top;
	}
	.indisplay {
		display: none !important;
	}
	.product_right_info {
		margin-bottom: 0;
	}
	.product-info {
		.tab-content #tab-specification table {
			display: block;
		}
		#tab-specification tbody.kompl {
			width: 100%;
			display: block;
			overflow: hidden;
			tr {
				display: block;
				width: 50%;
				float: left;
				td {
					background: url(#{$image-theme-path-base}menu_arrow2.png) 15px center no-repeat !important;
					padding: 10px 15px 10px 30px !important;
					width: 100%;
					display: block;
				}
			}
		}
		.advantages_block {
			display: none;
			&.nostock {
				position: static;
				width: 100%;
				.advantage_item {
					width: 100%;
				}
			}
		}
	}
	.widget-text {
		position: static;
		clear: both;
		width: 100%;
	}
	.price.detail ul li {
		text-align: left;
	}
	.product-markdown-view {
		float: unset;
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}

	#preim #about-company .title {
		text-align: center;
	}
	#preim #about-company .sub_title {
		text-align: center;
	}
	#preim #about-company .about-company-link {
		bottom: 0;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 2 - 40px);
		}
	}

	.page-product-search, .product-special {
		.product-grid {
			.product-block {
				width: calc(100% / 3 - 13px);
			}
		}
	}

	.price.detail ul li {
		text-align: left;
	}

	.product-info {
		.product-view {
			.prod_rating {
				.custom-color {
					&.otziv_button {
						padding-right: 0;
					}
				}
				.stock {
					margin-left: 16px;
				}
			}
		}
		.tab-content {
			background-position: left top;

			#tab-description {
				padding: 0;
			}
		}
	}
	.buy_products .quantity {
		overflow: hidden;
	}
	#checkout-page #coupon_form .coupon_button {
		display: block;
		margin: 0 0 15px 0;
		overflow: hidden;
	}
	#checkout-page #coupon_form .coupon_button .form-group {
		width: 130px;
		float: left;
		margin: 0;
	}
	#checkout-page #coupon_form .coupon_button input {
		border-radius: 5px;
		border: 0;
		width: 100%;
		padding: 0 4px;
	}
	#checkout-page #coupon_form .coupon_button button {
		float: right;
	}
	.btn-more-link {
		margin-bottom: 15px;
	}
	#preim {
		background: url(#{$image-theme-path-base}preim_768.jpg) right top no-repeat #fff;
	}
	#preim .col-sm-5 {
		width: 50%;
		margin-left: 0;
	}
	#preim .col-sm-4 {
		width: 50%;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	#preim #preim-block .preim-title .title {
		font-size: 24px;
	}
	#preim #preim-block .image {
		width: 50%;
		float: left;
	}
	#preim #preim-block .text {
		margin: 15px 0;
		width: 50%;
		float: left;
		font-size: 12px;
	}

	#partneram {
		background: url(#{$image-theme-path-base}partner_768.jpg) left top no-repeat;
		background-size: cover;
	}

	#bestseller .owl-prev {
		top: 50%;
		bottom: auto;
		left: -15px;
		margin-top: -25px;
		right: auto;
		color: #d21a20;
		width: 45px;
		height: 50px;
		background: url(#{$image-theme-path-base}slider_text.png) left top no-repeat #000;
		text-align: center;
		line-height: 50px;
		font-size: 22px;
	}
	#bestseller .owl-next {
		top: 50%;
		bottom: auto;
		left: auto;
		right: -15px;
		margin-top: -25px;
		color: #d21a20;
		width: 45px;
		height: 50px;
		background: #000;
		text-align: center;
		line-height: 50px;
		font-size: 22px;
	}
	#latest .owl-prev {
		top: 50%;
		bottom: auto;
		left: -37px;
		margin-top: -25px;
		right: auto;
		color: #d21a20;
		width: 45px;
		height: 50px;
		background: url(#{$image-theme-path-base}slider_text.png) left top no-repeat #000;
		text-align: center;
		line-height: 50px;
		font-size: 22px;
	}
	#latest .owl-next {
		top: 50%;
		bottom: auto;
		left: auto;
		right: -37px;
		margin-top: -25px;
		color: #d21a20;
		width: 45px;
		height: 50px;
		background: #000;
		text-align: center;
		line-height: 50px;
		font-size: 22px;
	}
	#related .owl-prev, #viewed .owl-prev {
		top: 50%;
		bottom: auto;
		left: -15px;
		margin-top: -25px;
		right: auto;
		color: #d21a20;
		width: 45px;
		height: 50px;
		background: url(#{$image-theme-path-base}slider_text.png) left top no-repeat #000;
		text-align: center;
		line-height: 50px;
		font-size: 22px;
	}
	#related .owl-next, #viewed .owl-next {
		top: 50%;
		bottom: auto;
		left: auto;
		right: -15px;
		background: #000;
		margin-top: -25px;
		color: #d21a20;
		width: 45px;
		height: 50px;
		text-align: center;
		line-height: 50px;
		font-size: 22px;
	}
	#powered .phone a {
		font-size: 16px;
	}
	#tm-newsletter .tm-login-form input {
		width: calc(100% - 150px);
	}
	.comparetable {
		padding: 0;
	}
	.comparetable table {
		min-width: 700px;
	}
	#searchtop {
		width: 300px;
		position: absolute;
		left: 15px;
		margin-left: 0;
		top: 100px;
	}
	.common-home #searchtop {
		width: 300px;
		position: absolute;
		left: 15px;
		margin-left: 0;
		top: 100px;
	}
	.title-product.product-title {
		text-align: left;
		padding: 0;
	}
	.image-container {
		margin: 10px 0 25px 0;
	}
	#total_price td {
		width: 70%;
	}
}

@media (max-width: $screen-xs-max) {
	body[class*="page-"] #content {
		padding: 0;
	}
	#buy_form_container {
		padding: 0 15px;
	}
	#slider_home .owl- {
		&prev {
			left: calc(50% - 24px);
		}
		&next {
			left: calc(50% + 24px);
		}
	}
	#preim .col-sm-5 {
		width: 100%;
		margin-left: 0;
	}
	#preim .col-sm-4:first-child {
		display: none;
	}
	#preim #about-company .about-company-link {
		position: relative;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 2 - 40px);
		}
	}
	.page-product-search, .product-special {
		.product-grid {
			.product-block {
				width: calc(100% / 2 - 13px);
			}
		}
	}
	.common-home .nav-search {
		position: absolute;
		bottom: auto;
		top: 170px;
		left: 0;
		height: 50px;
		width: 100%;
		z-index: 10;
	}
	.product-block .name {
		padding: 0 25px;
	}
	.logo-container {
		width: 140px;
	}
	.topcontacts {
		width: calc(100% - 140px);
	}
	.featured-category ul li {
		width: 100%;
	}
	#powered ul.links {
		margin-bottom: 40px;
	}
	.producttabs {
		margin-top: 15px;
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.tab-v3 {
		.nav-tabs {
			flex-wrap: wrap;
			border: none;
			& > li {
				width: 100%;
				text-align: center;
				border: none;
				&:last-child {
					border: none;
				}
				&.active a {
					border: none;
				}
				& > a {
					border: none;
					padding: 10px 15px;
					font-size: 18px;
				}
			}
		}
	}
	.h1_container {
		&:before {
			content: none;
		}
		h1 {
			width: 100%;
			font-size: 20px;
		}
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}
	.checkout-cart table,
	.shopping-cart-table,
	.shopping-cart-product-deleted {
		background: #fff;
	}
	.shopping-cart-table,
	.shopping-cart-product-deleted {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, & tr {
			display: block;
			border: 0;
			width: 100%;
		}
		tr {
			margin: 0 0 25px 0;
		}
		td {
			position: relative;
			padding-left: 43% !important;
			text-align: left !important;
			white-space: normal !important;
			&:first-child {
				border-top: 0;
				padding: 0 !important;
				text-align: center !important;
			}
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;
				@include transform(translateY(-50%));
				font-weight: bold;
				text-transform: uppercase;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}
	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}
	.header-bottom {
		#topcontacts {
			justify-content: center;
			min-width: 133px;
			.worktime {
				display: none;
			}
		}
	}
	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	.header-bottom {
		.main-menu {
			flex: 0 0 auto;
		}
	}
	#powered .inner {
		flex-wrap: wrap;
		.copyright {
			order: 2;
			margin-bottom: 15px;
		}
		.payment {
			order: 1;
			.payment-img {
				max-width: 100%;
			}
		}
	}
	#input-enquiry {
		height: 100px;
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
	}
	.breadcrumb > li {
		display: inline;
		font-size: 13px;
	}
	.breadcrumb > li:first-child a span {
		font-size: 13px
	}
	#slider_home .slider_home_item .top_title {
		margin-top: 0;
	}
	#slider_home .slider_home_item .title {
		font-size: 30px;
		line-height: normal;
	}
	.header-menu {
		height: 106px;
		position: inherit;
	}
	.common-home #searchtop {
		position: relative;
		top: auto;
		margin: 0 auto;
		left: auto;
	}
	.common-home .header-bottom > .container {
		height: 60px;
	}
	.header-menu > .container {
		position: inherit;
		display: block;
	}
	#header_top {
		position: relative;
		height: auto;
	}
	#header-layout {
		position: inherit;
		padding-bottom: 20px;
	}
	.common-home #header-layout {
		padding-bottom: 0;
	}
	.header-bottom #topcontacts {
		width: 310px;
	}
	#featured_categories {
		background-position: -1050px bottom;
	}
	#featured_categories .featured_cat {
		height: auto;
		background-size: cover;
		position: relative;
	}
	#featured_categories .featured_cat .featured_cat_block {
		padding: 250px 0 0 0;
		background-size: 200px;
		background-position: center top;
		height: 400px;
	}
	#featured_categories .featured_cat .featured_cat_info {
		width: 100%;
		padding: 0 25px;
	}
	#featured {
		padding: 25px;
	}
	#featured .title {
		text-align: center;
	}
	#featured .sub_title {
		text-align: center;
	}
	#bestseller {
		background-position: -400px bottom;
	}
	#preim {
		background: none;
		padding: 0;
	}
	#preim #preim-block {
		background: url(#{$image-theme-path-base}pre.jpg) no-repeat;
		background-size: cover;
		margin: 0 -15px;
		padding: 15px 30px 0 30px;
	}
	#preim #preim-block:after {
		content: '';
		width: calc(100% + 60px);
		height: 300px;
		background: url(#{$image-theme-path-base}pre2.jpg) center center no-repeat #111;
		background-size: cover;
		display: block;
		margin: 0 -30px;
	}
	#preim #preim-block .preim-title {
		position: relative;
	}
	#preim #preim-block .preim-title .title {
		font-size: 24px;
	}
	#preim #about-company .about-company-link {
		bottom: auto;
		margin-bottom: 15px;
	}
	#latest {
		padding-top: 0;
	}
	#partneram, #certificate-banner {
		background-size: cover;
	}
	#partneram, #certificate-banner {
		.title {
			margin-bottom: 15px;
			font-size: 19px;
		}
	}
	#widget_news .link {
		padding: 0 0 15px 0;
	}
	#tm-newsletter .title {
		text-align: center;
	}
	#tm-newsletter .sub_title {
		text-align: center;
	}
	.footer-center {
		padding: 15px 0 0 0;
		background: rgba(0, 0, 0, 0.5);
		margin: 0;
	}
	.footer-center .box .box-heading {
		margin: 10px 0;
	}
	.footer-center .footer-left {
		text-align: center;
		background: #111;
		margin-top: 15px;
		padding-top: 15px;
		padding-bottom: 0;
	}
	#powered {
		background: #111;
		text-align: center;
		padding-top: 0;
		margin: 0;
	}
	#powered .ft-addr {
		display: inline-block;
		line-height: 30px;
	}
	#powered .ft-phone {
		display: inline-block;
	}
	#powered .payment .payment-img {
		padding: 5px;
		background: #000;
		width: 100%;
		max-width: 100%;
		margin-top: 15px;
	}
	.footer-center .footer-name .footer-name-text {
		padding: 0 50px;
	}
	.stock-inner {
		float: right;
		padding: 7px 5px;
	}
	.product-label {
		top: 10px;
	}
	#slider_home .slider_home_item {
		background-position: right -480px bottom;
		background-size: auto;
		height: 400px;
	}
	#slider_home .slider_home_item .top_title {
		font-size: 24px;
		margin-top: 50px;
	}
	.preim, .partner {
		.col-xs-12 {
			padding: 0;
		}
	}
	.product-markdown-view {
		.order-1 {
			margin: 20px 0 ;
		}
	}
}

@media (max-width: 637px) {
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 45px 15px 15px 15px;
		.success_button {
			font-size: 11px;
			margin-top: 10px;
			padding: 0 10px;
		}
		& > div:first-child {
			margin-top: 20px;
		}
		br {
			display: none;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(33.3% - 20px);
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% / 2 - 7px);
	}
}

@media (max-width: 570px) {
	.fancy_holiday_works {
		width: 280px !important;
		.fancybox-skin {
			padding: 30px 10px !important;
			background-color: #fff;
			.fancybox-inner {
				width: 260px !important;
			}
			.holiday_table_wrap {
				font-size: 14px;
			}
			.holiday_table {
				td {
					font-size: 14px;
				}
			}
		}
	}
	#powered > .container {
		padding: 0;
	}
	.breadcrumbs {
		padding: 15px;
		.container {
			padding: 0;
		}
	}
	#searchtop {
		border: 0;
		.input-group-btn {
			height: 30px;
			&:hover {
				background: $tc;
			}
		}
		.btn-search {
			line-height: 14px;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.product-info .tab-content {
		.desc-header-wrapper .desc-header {
			display: block;
		}
		.scroll-button {
			margin-bottom: 15px;
			margin-left: 15px;
		}
	}
	.featured_cat {
		&, .col-xs-12 {
			padding: 0;
		}
	}
}

@media (max-width: $screen-xs) {
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	#featured_categories .featured_cat .featured_cat_info {
		.title {
			font-size: 22px;
			margin: 0;
			padding: 0;
			position: absolute;
			bottom: 30px;
			&:after {
				content: none;
			}
		}
		.desc {
			display: none;
		}
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.product-info .tab-content .product-grid.no-scroll .product-block {
		margin: 4px 2px;
		width: calc(100% - 5px);
	}

	.page-product-search, .product-special {
		.product-grid {
			.product-block {
				margin: 4px 2px;
				width: calc(100% - 5px);
			}
		}
	}

	.product-compare-header {
		padding-left: 0;
		width: 40px;
		.badge {
			right: 2px;
		}
	}
	#cart {
		.wrap-cart .badge {
			right: 7px;
		}
		.icon-cart {
			padding: 0 10px;
		}
	}

	.product-compare-header {
		margin-right: 5px;
	}

	#searchtop {
		width: 300px;
		position: absolute;
		left: 50%;
		margin-left: -150px;
		top: 160px;
	}
	#slider_home {
		.slider_home_item {
			background-position: right 0 bottom 50px;
			background-size: contain;
			height: 600px;

			.top_title {
				font-size: 24px;
				margin-top: 50px;
			}
			.text {
				display: none;
			}
		}
	}
	.product-block .block-img {
		width: 100%;
		float: none;
	}
	.product-block .product-meta {
		width: 100%;
		float: none;
	}
	#featured {
		background-size: cover;
		padding: 0;
	}
	.common-home .nav-search {
		position: absolute;
		bottom: auto;
		top: 180px;
		left: 0;
		height: 50px;
		width: 100%;
		z-index: 10;
	}
	#pavo-slideshow {
		margin-top: 50px;
	}
	.product-info .quantity {
		padding-bottom: 10px;
	}
	.product-info .product-meta .button-line {
		content: ' ';
		display: table;
		width: 100%;
		float: none;
		clear: both;
	}
	.product-info .product-meta .button-compare {
		padding-left: 0;
		margin-top: 0;
	}
	.product-info .tab-content #tab-specification tr:nth-child(2n) td {
		position: relative;
	}
	.product-info .tab-content #tab-specification tr:nth-child(2n) td:before {
		content: '';
		width: calc(100% + 60px);
		height: 100%;
		position: absolute;
		z-index: -1;
		left: -30px;
		background: #f6f6f6;
		top: 0;
	}
	.stock-inner {
		float: none;
		padding: 7px 5px;
	}
	#rev2 {
		display: none;
	}
	#rev1 {
		display: block;
		padding: 10px 0;
	}
	#rev1 .otziv_button {
		float: right;
	}
	.row.flex {
		display: flex;
		flex-wrap: wrap;

	}
	.row.flex .order-1 {
		order: 1;
		width: 100%;
	}
	.row.flex .order-2 {
		order: 2
	}
	.product_right_info {
		margin-top: 15px;
	}

	#checkout-page #pay-data #payment_methods .radio_custom {
		width: 100%;
		float: none;
	}

	.product-block:before {
		height: 20px;
		background-position: top center;
	}
	.product-block:after {
		height: 20px;
		background-position: bottom center;
	}
	.product-block .block-img .img {
		padding-top: 0;
	}
	.product-block .block-img .img img {
		display: inline-block !important;
		max-width: 100% !important;
		width: auto !important;
		height: auto;
	}
	.product-info #tab-specification tbody.kompl tr {
		width: 100%;
		float: none;
	}
	#bestseller .sub_title {
		margin: 0;
	}
	#powered {
		.payment {
			.payment-img {
				display: block;

				img {
					max-height: 60px;

					&:first-child {
						display: none;
					}
				}
			}
		}

		.payment2row {
			display: block;
			margin: 0 auto;

		}
	}
	.product-markdown-view {
		.quantity {
			padding-bottom: 0;
		}
	}
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}

	.product-block .bottom {
		width: 170px;
		margin: 10px auto 0 auto;
		text-align: center;
	}
	.product-block .bottom .to_cart {
		width: auto;
		padding-left: 15px;
		padding-right: 15px;
		margin: 0 auto;
		float: none;
		display: inline-block;
	}
	.product-block .btn-oneclick {
		width: 100%;
		text-align: center;
	}
	.header-bottom .headercart {
		right: 0;
	}
	#featured .owl-prev {
		left: -35px;
	}
	#featured .owl-next {
		right: -35px;
	}

	#latest .product-block, #bestseller .product-block, #related .product-block {
		margin: 15px 25px;
	}
	#preim #preim-block .text {
		font-size: 10px;
	}
	#featured_categories .title {
		line-height: 30px;
	}
	#widget_news .widget_news_item_img {
		margin: 0 0 5px 0;
	}
	#widget_news .widget_news_item_desc .widget_news_item_date {
		width: 35%;
		float: right;
	}
	#widget_news .widget_news_item_desc .widget_news_item_title {
		width: 100%;
		padding: 0;
	}
	body[class*="page-"] #content {
		padding: 0 0 20px 0;
	}
	.title-product.product-title {
		padding: 0 15px;
	}
	.product-info .product-view .prod_rating {
		padding: 10px 0 25px 15px
	}
	.product-info .tab-content #review-list .desc-header-wrapper .review-button {
		float: none;
		width: 100%;
	}
	.product-info .tab-content #review-list .desc-header-wrapper .review-button .btn {
		width: 100%;
	}
	.product-markdown-view {
		.credit_row {
			flex-direction: column;
		}
	}
}

@media (max-width: $screen-phone) {
	.product-grid {
		.product-block {
			width: calc(100% - 30px);
		}
	}

}
